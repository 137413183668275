
import timeZones from '@/constants/_TIME_ZONES.json'

const getTimeZone = function() {
  const index = timeZones.findIndex(
    item =>
      item.utc &&
      item.utc.indexOf(Intl.DateTimeFormat().resolvedOptions().timeZone) > -1
  )
  if (index > -1 && timeZones[index]) {
    return timeZones[index].text;
  } else {
    const offset = (new Date().getTimezoneOffset() / 60) * -1;
    const element = timeZones.find(e => e.offset === offset);
    if (element) {
      return element.text;
    }
  }
  return undefined;
}


export default getTimeZone;