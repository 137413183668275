<template>
  <div id="dashboard-audit">
    <!-- <empty-bots v-if="!hasBots && !isLoading"></empty-bots> -->
    <div class="dashboard-audit-content">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="audit-header">
            <h4 class="mb-4">{{ title }}</h4>

            <Filters
              v-if="filterModel.length"
              type="card"
              :filter-model="filterModel"
              @on-apply="onApplyFilters"
            />
            <AuditTable
              ref="table"
              @download="download"
            />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Filters from '@/views/home/metrics/filters/Filters.vue'
import AuditTable from './AuditTable.vue'
import downloadCsv from '@/utils/downloadCsv.js'

export default {
  data() {
    return {
      downloadCsv,
      filterModel: [],
      filters: {},
      datesApplied: [],
      statusFilterOptions: ''
    }
  },
  components: {
    Filters,
    AuditTable
  },
  methods: {
    getAudits([from,to]) {
      if (this.$refs.table) {
        this.$refs.table.setFilter({ from, to });
      }
    },
    download(reports) {
      const [from, to] = this.datesApplied;
      const filename = `konecta_audit_${from}_${to}`;
      const fields = Object.keys(reports[0]);
      const data = reports;
      this.downloadCsv(filename, fields, data);
    },
    init() {
      this.filterModel = [
        {
          name: 'dates',
          component: 'DateRangeFilter',
          width: 12,
          props: { value: this.weekInterval }
        },
      ]
      this.onApplyFilters();
    },
    onApplyFilters(res = {}) {
      if(!res.date) {
        res.date = {
          type: "date",
           value: [this.weekInterval.from, this.weekInterval.to]
        }
      }

      res.date.value = res.date.value.map(function(dateValue) {
        const date = dateValue;
        var toString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
        return toString;
      })
      this.datesApplied = res.date.value;
      this.getAudits(res.date.value);
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    title() {
      return this.lang.audit.title[this.languageSelected]
    },
    weekInterval() {
      const to = new Date()
      let from = new Date(to.getTime())
      from.setDate(from.getDate() - 7)
      return {
        from: new Date(from.setHours(0, 0, 0, 0)),
        to: new Date(to.setHours(23, 59, 59, 999))
      }
    },
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    if (this.$refs.table) {
      const [from, to] = this.datesApplied;
      this.$refs.table.setFilter({from, to});
    }
  }
}
</script>
