import Request from './request';
import state from '../store/state';
import getTimezone from '@/utils/getTimezone.js'
/**
 * Get token
 *
 * @returns {String} with the token retrieved from the local storage
 */
const _getToken = () => {
  const session = localStorage.getItem('session');
  if (session) {
    return JSON.parse(session).token;
  }
}

const PATH = {
  AUDIT: '/audit',
}

function _getAudits(from, to, page, limit) {
 const token = _getToken();
 const config = {
   headers: {
     authorization: `Bearer ${token}`,
     'X-Time-Zone': getTimezone()
   }
 };
 let limitStr = '';
 if(limit && !isNaN(limit) && limit > 0) limitStr = `&limit=${limit}`;

 return Request.get(`${state.config.konecta}${PATH.AUDIT}?from=${from}&to=${to}&page=${page}${limitStr}` , config);
}

export default {
  getAudits: _getAudits,
};
