<template>
  <section id="filters">
    <vs-card class="cardx" v-if="type === 'card'">
      <FiltersHeader :title="title" />
      <FiltersBody
        :filter-model="filterModel"
        v-on="$listeners"
        @change="onChange"
        ref="filtersBody"
      />
      <FiltersActions
        v-if="actionBar"
        :on-apply="onApply"
        :apply-enabled="applyEnabled"
        :on-reset="onReset"
      />
    </vs-card>

    <vs-collapse v-else-if="type === 'accordion'">
      <vs-collapse-item :open="true">
        <div slot="header">
          <FiltersHeader :title="title" :icon="icon" />
        </div>
        <FiltersBody
          :filter-model="filterModel"
          v-on="$listeners"
          @change="onChange"
          ref="filtersBody"
        />
        <FiltersActions
          v-if="actionBar"
          :on-apply="onApply"
          :apply-enabled="applyEnabled"
          :on-reset="onReset"
        />
      </vs-collapse-item>
    </vs-collapse>

    <template v-else>
      <FiltersHeader :title="title" />
      <FiltersBody
        :filter-model="filterModel"
        v-on="$listeners"
        @change="onChange"
        ref="filtersBody"
        direction="column"
      />
      <FiltersActions
        v-if="actionBar"
        :on-apply="onApply"
        :apply-enabled="applyEnabled"
        :on-reset="onReset"
        direction="column"
      />
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Filters',
  props: {
    filterModel: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: 'accordion'
    },
    title: {
      type: Boolean,
      default: true
    },
    icon: {
      type: Boolean,
      default: true
    },
    actionBar: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      filters: {},
      applyEnabled: false
    }
  },
  components: {
    FiltersHeader: () => import('./FiltersHeader.vue'),
    FiltersBody: () => import('./FiltersBody.vue'),
    FiltersActions: () => import('./FiltersActions.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    filterTitle() {
      return this.title
        ? this.lang.metrics.filters.title[this.languageSelected]
        : ''
    }
  },
  methods: {
    openAccordion() {
      setTimeout(() => {
        const accordion = document.querySelector('.vs-collapse-item--header')
        accordion.click()
      }, 1500)
    },
    onChange(filter) {
      if (this.actionBar) {
        this.applyEnabled = true
        this.filters[`${filter.type}`] = filter
      } else {
        this.$emit('on-apply', filter)
      }
    },
    onApply() {
      this.$emit('on-apply', this.filters)
      this.applyEnabled = false
    },
    onReset() {
      this.$refs.filtersBody.$children.forEach(filterComp => filterComp.clear())
      this.onApply()
    }
  }
}
</script>

<style lang="scss">
#filters {
  .vs-collapse {
    padding: 0 10px;
    background-color: white;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);

    &.shadow .open-item {
      box-shadow: none;
      margin: 0;
      .con-content--item {
        padding: 0 1rem 1rem;
      }
    }

    .vs-collapse-item {
      .con-content--item {
        opacity: 0;
      }
      &.open-item {
        .vs-collapse-item--content {
          overflow: visible;
        }
        .con-content--item {
          opacity: 1;
        }
      }
    }
  }
}
</style>
